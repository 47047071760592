import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useParams, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getClient, updateClient } from '../services/api'; // Import the API methods
import AddCreditPopup from './AddCreditPopup'; // Import the AddCreditPopup component

const FormContainer = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: auto;
  background-color: #f7f8fa;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px 40px; /* Adjusted the horizontal gap to add space between columns */
`;

const FormRow = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-weight: bold;
  margin-bottom: 5px;
`;

const Input = styled.input`
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
`;

const FullWidthInput = styled(Input)`
  grid-column: span 2;
`;

const Button = styled.button`
  padding: 10px;
  background-color: #343a40;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  grid-column: span 2;
  justify-self: center;

  &:hover {
    background-color: #495057;
  }

  &:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
  }
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Loader = styled.div`
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #343a40; /* Dark grey */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: ${spin} 2s linear infinite;
  margin: auto;
`;

const ClientDetails = () => {
  const { clientId } = useParams();
  const [clientData, setClientData] = useState({
    name: '',
    initial: '',
    superAdminFirstName: '',
    superAdminLastName: '',
    superAdminEmail: '',
    credits: 0,
    isActive: false,
  });
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchClient = async () => {
      try {
        const response = await getClient(clientId);
        setClientData(response.data);
      } catch (error) {
        toast.error(error.response?.data?.message ?? 'Something Went Wrong');
      } finally {
        setLoading(false);
      }
    };

    fetchClient();
  }, [clientId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setClientData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUpdating(true);

    try {
      await updateClient(clientId, clientData);
      toast.success('Client updated successfully');
      navigate('/clients');
    } catch (error) {
      toast.error(error.response?.data?.message ?? 'Something Went Wrong');
    } finally {
      setUpdating(false);
    }
  };

  const handleAddCredit = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  if (loading) return <Loader />;

  return (
    <FormContainer>
      <ToastContainer />
      <Form onSubmit={handleSubmit}>
        <FormRow>
          <Label>Client Name</Label>
          <Input
            type="text"
            name="name"
            value={clientData.name}
            onChange={handleChange}
            required
          />
        </FormRow>
        <FormRow>
          <Label>Client Initial</Label>
          <Input
            type="text"
            name="initial"
            value={clientData.initial}
            onChange={handleChange}
            required
            minLength="3"
            maxLength="3"
            pattern=".{3}"
            title="Initial must be exactly 3 characters"
          />
        </FormRow>
        <FormRow>
          <Label>Admin First Name</Label>
          <Input
            type="text"
            name="superAdminFirstName"
            value={clientData.superAdminFirstName}
            onChange={handleChange}
            required
          />
        </FormRow>
        <FormRow>
          <Label>Admin Last Name</Label>
          <Input
            type="text"
            name="superAdminLastName"
            value={clientData.superAdminLastName}
            onChange={handleChange}
            required
          />
        </FormRow>
        <FormRow>
          <Label>Admin Email</Label>
          <FullWidthInput
            type="email"
            name="superAdminEmail"
            value={clientData.superAdminEmail}
            onChange={handleChange}
            required
          />
        </FormRow>
        <FormRow>
          <Label>Credits</Label>
          <Input
            type="number"
            name="credits"
            value={clientData.credits}
            onChange={handleChange}
            required
          />
        </FormRow>
        <FormRow>
          <Label>Active</Label>
          <Input
            type="checkbox"
            name="isActive"
            checked={clientData.isActive}
            onChange={(e) =>
              setClientData((prevData) => ({
                ...prevData,
                isActive: e.target.checked,
              }))
            }
          />
        </FormRow>
        <Button type="submit" disabled={updating}>
          {updating ? <Loader /> : 'Update Client'}
        </Button>
        <Button type="button" onClick={handleAddCredit}>
          Add Credit
        </Button>
      </Form>
      {showPopup && <AddCreditPopup clientId={clientId} onClose={closePopup} />}
    </FormContainer>
  );
};

export default ClientDetails;
