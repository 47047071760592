import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getClients } from '../services/api'; // Import the API method

const Container = styled.div`
  padding: 20px;
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px; /* Adjusted margin to remove extra space */
`;

const AddClientButton = styled.button`
  background-color: #343a40;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background-color: #495057;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const TableHeader = styled.th`
  border: 1px solid #ddd;
  padding: 12px;
  background-color: #343a40;
  color: #fff;
  text-align: left;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
  cursor: pointer;

  &:hover {
    background-color: #f1f1f1;
  }
`;

const TableCell = styled.td`
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Loader = styled.div`
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #343a40; /* Dark grey */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: ${spin} 2s linear infinite;
  margin: auto;
`;

const StatusCircle = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: ${({ isActive }) => (isActive ? 'green' : 'gray')};
  margin: auto;
`;

const formatDate = (dateString) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

const ClientList = () => {
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await getClients();
        setClients(response.data);
      } catch (error) {
        toast.error(error.response?.data?.message ?? 'Something Went Wrong');
      } finally {
        setLoading(false);
      }
    };

    fetchClients();
  }, []);

  const handleAddClient = () => {
    navigate('/clients/create-client');
  };

  const handleRowClick = (client) => {
    navigate(`/clients/${client._id}`, { state: { clientName: client.name } });
  };

  return (
    <Container>
      <ToastContainer />
      <Header>
        <AddClientButton onClick={handleAddClient}>Add Client</AddClientButton>
      </Header>
      {loading ? (
        <Loader />
      ) : (
        <Table>
          <thead>
            <tr>
              <TableHeader>Name</TableHeader>
              <TableHeader>Initial</TableHeader>
              <TableHeader>Admin Name</TableHeader>
              <TableHeader>Admin Email</TableHeader>
              <TableHeader>Active</TableHeader>
              <TableHeader>Credits</TableHeader>
              <TableHeader>Created At</TableHeader>
            </tr>
          </thead>
          <tbody>
            {clients.map((client, index) => (
              <TableRow key={index} onClick={() => handleRowClick(client)}>
                <TableCell>{client.name}</TableCell>
                <TableCell>{client.initial}</TableCell>
                <TableCell>{`${client.superAdminFirstName} ${client.superAdminLastName}`}</TableCell>
                <TableCell>{client.superAdminEmail}</TableCell>
                <TableCell>
                  <StatusCircle isActive={client.isActive} />
                </TableCell>
                <TableCell>{client.credits}</TableCell>
                <TableCell>{formatDate(client.createdAt)}</TableCell>
              </TableRow>
            ))}
          </tbody>
        </Table>
      )}
    </Container>
  );
};

export default ClientList;
